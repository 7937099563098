import { useTranslation } from "react-i18next";
import defaultContent from "../../../../content/bfiMetric";
import { IncentiveCalculation } from "../../../../common/components/IncentiveCalculation/IncentiveCalculation";
import { IconMultiply } from "../../../../common/icons/IconMultiply/IconMultiply";
import { IconEquals } from "../../../../common/icons/IconEquals/IconEquals";
import "./BFIMetric.scss";
import { useGetBronzeEarningQuery } from "../../../../services/bronzeIncentivesAPI";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { storedLocale } from "../../../impersonation/util";
import { getLoginAffAbo } from "../../../../reducers/model/boot";

type Props = {
  awardCode: number;
  show?: boolean;
};

const BFIMetric = ({ awardCode, show = true }: Props) => {
  const { t } = useTranslation(["bfiIncentiveProgress"]);

  const locale = storedLocale();

  const boot = useAppSelector(({ boot }) => boot);
  const { selectedPeriod } = useAppSelector((state) => state.period);

  const affAbo = getLoginAffAbo({ boot });

  const { data, isFetching } = useGetBronzeEarningQuery({
    affAbo: affAbo,
    period: selectedPeriod,
    awardCode: awardCode,
    locale: locale,
  });
  const { basis, multiplier, bonus } = data || {};

  if (show === false) return <></>;

  return (
    <div className="bfi-metric">
      <div className="bfi-metric__content">
        <h2 className="bfi-metric__header">{t("header", defaultContent["header"])}</h2>
        <div className="bfi-metric__incentive-calculation">
          <IncentiveCalculation
            isLoading={isFetching}
            base={{
              title: t("performanceBonus", defaultContent["performanceBonus"]),
              amount: basis?.amountCurrency || "0",
            }}
            multiplier={{
              title: t("multiplier", defaultContent["multiplier"]),
              amount: `${multiplier}%`,
              symbolIcon: <IconMultiply />,
            }}
            total={{
              title: t("currentTotal", defaultContent["currentTotal"]),
              amount: bonus?.amountCurrency || "0",
              symbolIcon: <IconEquals />,
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default BFIMetric;
