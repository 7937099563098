import { Trans, useTranslation } from "react-i18next";
import { TimeStamp } from "../../common/components/TimeStamp/TimeStamp";
import defaultContent from "../../content/leadershipSeminar";
import "./LeadershipSeminar.scss";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";
import { PQRequirementsType } from "../../common/interfaces/leadershipSeminar";
import { QUALIFICATION_STATUS } from "../../common/enums/incentive-enums";
import { RequirementTileMapper } from "../../common/components/RequirementTileMapper/RequirementTileMapper";
import { REQUIREMENT_NAME } from "../../common/enums/requirements";
import PQRequirements from "./PQRequirements/PQRequirements";
import { LSIBanner } from "./LSIBanner/LSIBanner";

export default function LeadershipSeminar() {
  const { t } = useTranslation(["leadershipSeminar"]);

  const mockPQRequirementData: PQRequirementsType = {
    pqHistory: [
      {
        status: QUALIFICATION_STATUS.QUALIFIED,
        period: "202309",
      },
      {
        status: QUALIFICATION_STATUS.QUALIFIED,
        period: "202310",
      },
      {
        status: QUALIFICATION_STATUS.QUALIFIED,
        period: "202311",
      },
      {
        status: QUALIFICATION_STATUS.QUALIFIED,
        period: "202312",
      },
      {
        status: QUALIFICATION_STATUS.QUALIFIED,
        period: "202401",
      },
      {
        status: QUALIFICATION_STATUS.QUALIFIED,
        period: "202402",
      },
      {
        status: QUALIFICATION_STATUS.QUALIFIED,
        period: "202403",
      },
      {
        status: QUALIFICATION_STATUS.QUALIFIED,
        period: "202404",
      },
      {
        status: QUALIFICATION_STATUS.QUALIFIED,
        period: "202405",
      },
      {
        status: QUALIFICATION_STATUS.QUALIFIED,
        period: "202406",
      },
      {
        status: QUALIFICATION_STATUS.TRACKING,
        period: "202407",
      },
      {
        status: QUALIFICATION_STATUS.TRACKING,
        period: "202408",
      },
    ],
    totalPqs: 12,
    achievedPqs: 10,
    status: QUALIFICATION_STATUS.NOT_QUALIFIED,
  };

  const totalRequirements = 3;
  const totalRequirementsMet = 3;

  const requirementMockData = {
    VolumeRequirements: [
      {
        Name: REQUIREMENT_NAME.PERSONAL,
        Met: true,
        Exempt: false,
        Volume: {
          Current: {
            Actual: 20611.63,
            Formatted: "20,611.63",
          },
          Target: {
            Actual: 1800,
            Formatted: "1,800.00",
          },
          Remaining: {
            Actual: 0,
            Formatted: "0.00",
          },
        },
      },
    ],
    PercentRequirements: [
      {
        Name: REQUIREMENT_NAME.CHECK_FOR_PLATINUM,
        Met: true,
        Percent: {
          Current: {
            Actual: 94,
            Formatted: "94%",
          },
          Target: {
            Actual: 60,
            Formatted: "60%",
          },
          Remaining: {
            Actual: 0,
            Formatted: "0%",
          },
        },
        Volume: {
          Current: {
            Actual: 19375.350000000002,
            Formatted: "19,375.35",
          },
          Target: {
            Actual: 20611.629999999997,
            Formatted: "20,611.63",
          },
          Remaining: {
            Actual: 0,
            Formatted: "0.00",
          },
        },
      },
    ],
    BadgeRequirements: [
      {
        Name: REQUIREMENT_NAME.BASIS_FOUNDERS_PLATINUM,
        Met: true,
        Target: "1",
        Status: QUALIFICATION_STATUS.QUALIFIED,
        ShowLabel: true,
      },
    ],
    TotalRequirements: 3,
    RequirementsMet: 3,
    RequirementMetStatuses: [true, true, true],
  };

  return (
    <main className="LeadershipSeminar">
      <LSIBanner Status={QUALIFICATION_STATUS.TRACKING} isLoading={false} />
      <div className="LeadershipSeminar__pageTitle">
        <div className="LeadershipSeminar__pageTitle--title">
          <Trans>{`${t("pageTitle.title", defaultContent["pageTitle"]["title"])}`}</Trans>
        </div>
        <div className="LeadershipSeminar__pageTitle--timestamp">
          <TimeStamp dateFormat={"long"} />
        </div>
      </div>
      <div className="LeadershipSeminar__requirementsSection">
        <div className="LeadershipSeminar__requirementsSection--title">
          {`${t("requirements.sectionHeader", defaultContent["requirements"]["sectionHeader"])}`}
        </div>
        <div className="LeadershipSeminar__requirementsSection--description">
          <Trans>
            {t("requirements.description", defaultContent.requirements["description"], {
              current: totalRequirementsMet,
              needed: totalRequirements,
            })}
            <span
              className={totalRequirements === totalRequirementsMet ? "Numbers__bold Numbers__met" : "Numbers__bold"}
            ></span>
          </Trans>
        </div>
        <div className="LeadershipSeminar__requirementsSection--tileSection">
          <RequirementTileMapper isLoading={false} requirements={requirementMockData} origin="lsi" />
        </div>
        <div className="LeadershipSeminar__requirementsSection--pqTile">
          <PQRequirements pqRequirements={mockPQRequirementData} isLoading={false} showPQRequirementsTile={true} />
        </div>
      </div>
      <div className="LeadershipSeminar__footer">
        <BonusInfoFooter />
      </div>
    </main>
  );
}
