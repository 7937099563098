import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { LeafBannerToast } from "../../../common/leaf/LeafBannerToast/LeafBannerToast";
import { IconLightning } from "../../../common/icons/IconLightning/IconLightning";
import { useAppSelector } from "../../../store";
import { storedLocale } from "../../impersonation/util";
import defaultContent from "../../../content/bronzeIncentives";
import { getLoginAffAbo } from "../../../reducers/model/boot";
import { useGetBronzeEligibilityQuery } from "../../../services/bronzeIncentivesAPI";
import "./BronzeBannerToast.scss";

const BronzeBannerToast = () => {
  const { t } = useTranslation(["bronzeIncentives"]);
  const locale = storedLocale();
  const boot = useAppSelector(({ boot }) => boot);
  const affAbo = getLoginAffAbo({ boot });
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { isRestartProgram, awardCode } = useAppSelector((state) => state.bronzeIncentive);

  // Use skipToken to skip the query when awardCode is zero
  const { data, isFetching } = useGetBronzeEligibilityQuery(
    awardCode === 0
      ? skipToken // Skip the query
      : { affAbo, period: selectedPeriod, awardCode, locale },
  );
  const { months = [] } = data || {};

  const [showBanner, setShowBanner] = useState(true);

  const show = isRestartProgram && months.length > 1 && showBanner && isFetching === false;

  const hideBanner = () => {
    setShowBanner(false);
  };

  return (
    <div className="bronze-banner-toast">
      <LeafBannerToast theme="blue" show={show} closeFunction={hideBanner}>
        <div className="bronze-banner-toast__message">
          <IconLightning />
          <p>{t("bannerMessage", defaultContent["bannerMessage"], { noOfMonths: months.length })}</p>
        </div>
      </LeafBannerToast>
    </div>
  );
};

export default BronzeBannerToast;
