import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query";
import { useAppSelector } from "../../../../store";
import { QUALIFICATION_STATUS } from "../../../../common/enums/incentive-enums";
import { getBronzeStatus } from "../../BronzeCommon/BronzeStatus/BronzeStatus";
import LeafIncentiveBannerTile from "../../../../common/leaf/LeafIncentiveBannerTile/LeafIncentiveBannerTile";
import defaultContent from "../../../../content/bbiBannerTile";
import { getBronzeBonusAmount } from "../../BronzeCommon/BronzeAmount/BronzeAmount";
import { getBronzeDisplayDate } from "../../BronzeCommon/BronzeDate/BronzeDate";
import {
  useGetBronzeConsistencyPaymentQuery,
  useGetBronzeEarningQuery,
  useGetBronzeEligibilityQuery,
} from "../../../../services/bronzeIncentivesAPI";
import { storedLocale } from "../../../impersonation/util";
import { getLoginAffAbo } from "../../../../reducers/model/boot";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";

type Props = {
  awardCode: number;
  isFixedPayment?: boolean;
  isCompactView?: boolean;
  fixedPaymentAwardCode: number;
};

const BFIBannerTile = ({ awardCode, isFixedPayment = false, isCompactView = false, fixedPaymentAwardCode }: Props) => {
  const { t } = useTranslation(["bbiBannerTile"]);

  const locale = storedLocale();

  const boot = useAppSelector(({ boot }) => boot);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const learnMoreLink = useAppSelector((store) => store.boot?.configuration?.dashboardLinks?.learnMore?.bronzeBuilder);

  const { isoCountryCode = "" } = boot?.user || {};
  const affAbo = getLoginAffAbo({ boot });

  const { data: earningAPIResponse, isFetching: isEarningDataFetching } = useGetBronzeEarningQuery({
    affAbo,
    period: selectedPeriod,
    awardCode,
    locale,
  });

  const { data: eligibilityAPIResponse, isFetching: isEligibilityDataFetching } = useGetBronzeEligibilityQuery({
    affAbo,
    period: selectedPeriod,
    awardCode,
    locale,
  });

  const { data: consistencyAPIResponse, isFetching: isConsistencyDataFetching } = useGetBronzeConsistencyPaymentQuery(
    isFixedPayment === false
      ? skipToken // Skip the query when isFixedPayment is false
      : {
          affAbo,
          period: selectedPeriod,
          awardCode: fixedPaymentAwardCode,
          countryCode: isoCountryCode,
          locale,
        },
  );

  const {
    status = "",
    method = "",
    isoCountry = "",
    bonus = { amount: 0, isoCurrency: "" },
  } = earningAPIResponse || {};
  const { eligible = false, graduated = false } = eligibilityAPIResponse || {};
  const { met = false, bonusAmount = 0 } = consistencyAPIResponse || {};
  const consistencyPayment = { met, bonusAmount };

  const isLoading =
    isEarningDataFetching === true || isEligibilityDataFetching === true || isConsistencyDataFetching === true;

  if (!isLoading) {
    const overridedStatus = getBronzeStatus({
      status,
      method,
      eligible,
      graduated,
    });

    const bronzeBonusAmount = getBronzeBonusAmount({
      isoCountry,
      isFixedPayment,
      bonus,
      consistencyPayment,
    });

    const bannerMessages = [
      {
        status: [
          QUALIFICATION_STATUS.NOT_QUALIFIED,
          QUALIFICATION_STATUS.ELIGIBLE,
          QUALIFICATION_STATUS.PENDING_ELIGIBLE,
        ],
        title: `${t("keepGoing", defaultContent["keepGoing"])}`,
      },
      {
        status: [QUALIFICATION_STATUS.DENIED, QUALIFICATION_STATUS.NOT_ELIGIBLE],
        title: `${t("notEligible", defaultContent["notEligible"], { dateText: getBronzeDisplayDate(selectedPeriod) })}`,
        subtitle: `${t("moreInfo", defaultContent["moreInfo"])}`,
      },
      {
        status: [QUALIFICATION_STATUS.QUALIFIED, QUALIFICATION_STATUS.TRACKING],
        title: `${t("greatJob", defaultContent["greatJob"])}`,
      },
      {
        status: [QUALIFICATION_STATUS.MANUALLY_QUALIFIED],
        title: `${t("manual", defaultContent["manual"])}`,
        subtitle: `${t("moreInfo", defaultContent["moreInfo"])}`,
      },
      {
        status: [QUALIFICATION_STATUS.HELD],
        title: `${t("onHold", defaultContent["onHold"])}`,
        subtitle: `${t("moreInfo", defaultContent["moreInfo"])}`,
      },
    ];

    const statusToHideIncentive = [
      QUALIFICATION_STATUS.DENIED,
      QUALIFICATION_STATUS.NOT_ELIGIBLE,
      QUALIFICATION_STATUS.HELD,
    ];

    return (
      <div className="BFIBannerTile">
        <LeafIncentiveBannerTile
          bannerMessages={bannerMessages}
          incentive={{
            title: `${t("incentiveLabel", defaultContent["incentiveLabel"])}`,
            status: overridedStatus,
            amount: bronzeBonusAmount,
            statusToHideIncentive,
          }}
          learnMoreLink={learnMoreLink}
          isLoading={isLoading}
          isCompactView={isCompactView}
        />
      </div>
    );
  }
  return <LeafTile isLoading={isLoading} />;
};

export default BFIBannerTile;
