import { useTranslation } from "react-i18next";
import defaultContent from "../../../../content/bronzeConsistencyPaymentDetails";
import { LeafModal } from "../../../../common/leaf/LeafModal/LeafModal";
import { Schedule } from "../../../../reducers/model/bronze/consistencyPaymentMetric";
import "./BronzeConsistencyPaymentDetails.scss";

type Props = {
  module: string;
  showModal: boolean;
  onModalClose: Function;
  schedules: Schedule[];
  targetRequired: number;
  met: boolean;
};

const BronzeConsistencyPaymentDetails = (props: Props) => {
  const { t } = useTranslation(["bronzeConsistencyPaymentDetails"]);
  const { module, showModal, onModalClose, schedules, targetRequired, met } = props;

  const children = (
    <div className="bronze-consistency-payment-details__content">
      <h1 className="bronze-consistency-payment-details__header">
        <p>{t(module, defaultContent[module])}</p>
        <p>{t("subTitle", defaultContent["subTitle"])}</p>
      </h1>
      <div className="bronze-consistency-payment-details__schedule-list">
        {schedules.map(({ target, amount }) => (
          <div
            key={target}
            className={`bronze-consistency-payment-details__schedule bronze-consistency-payment-details__schedule${
              target === targetRequired && met === true ? "--met" : ""
            }`}
          >
            <div className="bronze-consistency-payment-details__target">{`${t(
              "paymentEarned",
              defaultContent["paymentEarned"],
              {
                target: target,
              },
            )}`}</div>
            <div className="bronze-consistency-payment-details__amount">{`${t(
              "additionalBonus",
              defaultContent["additionalBonus"],
              {
                bonus: amount,
              },
            )}`}</div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <LeafModal open={showModal} onCloseCallbackFn={onModalClose}>
      {children}
    </LeafModal>
  );
};
export default BronzeConsistencyPaymentDetails;
