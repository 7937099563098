import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { periodFromDate } from "@amwaycommon/acc-mixins/dates";
import { updatePeriod } from "../../reducers/period";
import { useAppDispatch } from "../../store";
import ABOProfileSlider from "./ABOProfileSlider/ABOProfileSlider";
import ABOApplicantSlider from "./ABOApplicantSlider/ABOApplicantSlider";

export type Props = {
  previousElement?: HTMLElement;
  useCurrentMonthData?: boolean;
};

const ABOProfile = (props: Props) => {
  const { previousElement, useCurrentMonthData = false } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (useCurrentMonthData === true) {
      dispatch(updatePeriod({ period: periodFromDate(new Date()) }));
    }
  }, [dispatch, useCurrentMonthData]);

  useTranslation([
    "aboProfileSlider",
    "leafGenderInfo",
    "leafBirthdayInfo",
    "leafAboName",
    "common",
    "leafErrorMessage",
    "customerTags",
    "leafApplicantAboName",
    "leafAboType",
    "leafAboPhoneNumber",
    "leafAboEmail",
    "leafAboAddress",
    "leafPreferredLanguageInfo",
    "aboProfileTabViews",
    "leafApplicantCarousel",
    "aboApplicantSlider",
    "leafBirthdayInfo",
    "leafRenewalDateInfo",
    "leafDateJoinedInfo",
    "leafRecentSponsorDateInfo",
    "leafRecentOrderDateInfo",
    "common",
    "sponsorshipTab",
    "leafSponsorTypeLabel",
    "aboOverviewTab",
    "leafPinInfo",
    "leafAdditionalRegInfo",
    "aboProfilePerfHistory",
    "aboProfilePinInfo",
    "aboProfilePrimaryAddress",
    "aboProfileAdditionalInfo",
  ]);

  return (
    <>
      <ABOProfileSlider previousElement={previousElement} />
      <ABOApplicantSlider />
    </>
  );
};

export default ABOProfile;
