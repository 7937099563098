const removeLeadingZero = (numberString: string) => {
  const stringLength = numberString.length;
  if (stringLength > 0 && numberString[0] === "0") {
    return numberString.slice(1, stringLength);
  }

  return numberString;
};

/**
 * Returns accurate date object based on local time from period string to use for formatting with Intl.DateTimeFormat.
 *
 * @param period "202406"
 * @returns Date "Sat Jun 01 2024 00:00:00 GMT-0400 (Eastern Daylight Time) "
 */
const getDateObject = (period: string) => {
  const { year, month } = getMonthAndYear(period);
  return new Date(Number(year), Number(month) - 1);
};

export const getMonthAndYear = (period: string): { year: string; month: string } => {
  if (period.length !== 6) {
    return { year: "", month: "" };
  }
  const year = period.slice(0, 4);
  const month = period.slice(4, 6);
  const formattedMonth = removeLeadingZero(month);

  if (Number(formattedMonth) > 12) return { year: "", month: "" };

  return { year, month };
};

/**
 * Returns 4 digit PY (Performance Year)
 * @param period - The period string in either 'YYYY' or 'YYYYMM' format.
 * @returns ex: "2024"
 */
export const getPerformanceYear = (period: string): string => {
  const year = period.slice(0, 4);
  const month = period.length === 6 ? period.slice(4, 6) : "01"; // Default to January if only year is provided

  if (Number(month) >= 9) {
    const performanceYear = Number(year) + 1;
    return performanceYear.toString();
  }
  return year;
};

/**
 * Returns 2 digit PY (Performance Year)
 * @param period - The period string in either 'YYYY' or 'YYYYMM' format.
 * @returns ex: "24"
 */
export const getPerformanceYearShort = (period: string): string => {
  if (period.length === 4) {
    return getPerformanceYear(period).slice(2, 4);
  } else if (period.length === 6) {
    return getPerformanceYear(period).slice(2, 4);
  } else {
    throw new Error("Invalid period format. Expected 'YYYY' or 'YYYYMM'.");
  }
};

/**
 * Returns the performance year data for a given period, adjusted backward by a specified count.
 *
 * @param period "202405"
 * @param offsetYears The number of years to go back from the performance year. "1"
 * @returns selectedPerformanceYear: "2023", selectedPYStartPeriod: "202309"; selectedPYEndPeriod: "202408"
 */
export const getSelectedPerformanceYearData = (
  period: string,
  offsetYears: number,
): { selectedPerformanceYear: string; selectedPYStartPeriod: string; selectedPYEndPeriod: string } => {
  const { year, month } = getMonthAndYear(period);
  let performanceYear = Number(year);

  if (Number(month) >= 9) {
    performanceYear += 1;
  }

  performanceYear -= offsetYears;

  return {
    selectedPerformanceYear: performanceYear.toString(),
    selectedPYStartPeriod: `${performanceYear - 1}09`,
    selectedPYEndPeriod: `${performanceYear}08`,
  };
};

/**
 * @returns "202406"
 */
export const getCurrentPeriod = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  return `${year}${month}`;
};

/**
 * @param period "202406"
 * @param locale "en-US"
 * @returns "Jun 2024"
 */
export const getShortPeriodFormat = (period: string, locale: string) => {
  const dateObject = getDateObject(period);
  const shortDateFormatter = Intl.DateTimeFormat(locale, {
    month: "short",
    year: "numeric",
    calendar: "gregory",
  });
  const shortDate = shortDateFormatter.format(dateObject);
  return shortDate;
};

/**
 * @param period "202406"
 * @param locale "en-US"
 * @returns "Jun 24"
 */
export const getShortMonthYear = (period: string, locale: string) => {
  const dateObject = getDateObject(period);
  const shortDateFormatter = Intl.DateTimeFormat(locale, {
    month: "short",
    year: "2-digit",
  });
  const shortDate = shortDateFormatter.format(dateObject);
  return shortDate;
};

/**
 * @param period "202406"
 * @param locale "en-US"
 * @returns "June 2024"
 */
export const getLongPeriodFormat = (period: string, locale: string) => {
  const dateObject = getDateObject(period);
  const longDateFormatter = Intl.DateTimeFormat(locale, {
    month: "long",
    year: "numeric",
    calendar: "gregory",
  });
  const shortDate = longDateFormatter.format(dateObject);
  return shortDate;
};

/**
 * @param period "202406"
 * @param locale "ja-JP"
 * @returns "2024年"
 */
export const getYear = (period: string, locale: string) => {
  const dateObject = getDateObject(period);
  const yearFormatter = Intl.DateTimeFormat(locale, {
    year: "numeric",
    calendar: "gregory",
  });
  const formatYear = yearFormatter.format(dateObject);
  return formatYear;
};

/**
 * @param period "202406"
 * @param locale "en-US"
 * @returns "June"
 */
export const getFullMonth = (period: string, locale: string) => {
  const dateObject = getDateObject(period);
  const monthFormatter = Intl.DateTimeFormat(locale, {
    month: "long",
  });
  const formatMonth = monthFormatter.format(dateObject);
  return formatMonth;
};

/**
 * @param period "202406"
 * @param locale "en-US"
 * @returns "Jun"
 */
export const getShortMonth = (period: string, locale: string) => {
  const dateObject = getDateObject(period);
  const monthFormatter = Intl.DateTimeFormat(locale, {
    month: "short",
  });
  const formatMonth = monthFormatter.format(dateObject);

  return formatMonth;
};
