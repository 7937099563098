import { ChartDonut, ChartThemeColor } from "@patternfly/react-charts";
import "./LeafProgressCircle.scss";
import { useEffect, useRef } from "react";
import tinygradient from "tinygradient";
import { formatAboBV } from "@amwaycommon/acc-mixins/l10n";
import LeafCircleLoader from "../LeafLoadingSkeleton/LeafCircleLoader/LeafCircleLoader";
import { useAppSelector } from "../../../store";

export type FormattedLabelValues = {
  currentAmount?: string;
  targetAmount?: string;
};

export type Props = {
  subtitle?: string;
  currentAmount: number;
  targetAmount: number;
  isLoading: boolean;
  threeColorCustomGradient?: Array<string>;
  smoothColorTransition?: boolean;
  labels?: Array<string>;
  animationDuration?: number;
  showLabelsOnly?: boolean;
};

const LeafProgressCircle = (props: Props) => {
  const {
    subtitle = "",
    currentAmount,
    targetAmount,
    isLoading,
    threeColorCustomGradient = ["#AFAFAF", "#DA7600", "#107F47"],
    smoothColorTransition = false,
    labels = [undefined, undefined],
    animationDuration = 2000,
    showLabelsOnly,
  } = props;
  const { user } = useAppSelector((state) => state.boot);
  const local = useAppSelector((state) => state.loginLocalStorage.loginFields.locale) || "en-US";
  const ref = useRef(null);
  const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);
  const gradient = tinygradient(threeColorCustomGradient);
  const percentage = currentAmount / targetAmount;
  const currentPercentage = clamp(percentage, 0, 1);
  const renderLabels = (datum: any) => {
    if ((labels[0] !== undefined || labels[1] !== undefined) && labels.length === 2) {
      return showLabelsOnly ? `${datum.x}` : `${datum.x}: ${datum.y}`;
    }

    // when the current percentage is 0 set the tooltip value to the current percentage (value 0).
    return currentPercentage ? `${datum.y}` : `${currentPercentage}`;
  };

  // this controls the colors on the progress wheel
  useEffect(() => {
    if (ref && ref.current) {
      const progressBar = (ref.current as HTMLElement).querySelector(
        ".pf-v5-c-chart > svg > g > path:nth-child(1)",
      ) as HTMLElement;

      if (smoothColorTransition) {
        progressBar.style.fill = `${gradient.rgbAt(currentPercentage)}`;
      } else if (currentPercentage <= 0) {
        progressBar.style.fill = `${threeColorCustomGradient[0]}`;
      } else if (currentPercentage < 1) {
        progressBar.style.fill = `${threeColorCustomGradient[1]} `;
      } else if (currentPercentage === 1) {
        progressBar.style.fill = `${threeColorCustomGradient[2]}`;
      }
    }
  }, [currentAmount, targetAmount, currentPercentage, gradient, smoothColorTransition, threeColorCustomGradient]);

  if (isLoading) {
    return (
      <div className="LeafProgressCircle">
        <div className="LeafProgressCircle__Loader">
          <LeafCircleLoader isLoading={isLoading} width="100%" height="100%" />
        </div>
      </div>
    );
  }

  const titleValue = formatAboBV(currentAmount, local, user.isoCountryCode);

  const setTitleFontSize = () => {
    if (!titleValue) return 24;
    if (titleValue.length >= 15) return 17;
    if (titleValue.length === 14) return 19;
    if (titleValue.length === 13) return 21;
    if (titleValue.length === 12) return 22;
    return 24;
  };

  return (
    <div className="leaf-progress-circle-container" ref={ref}>
      <div className="leaf-progress-circle-container__text-container">
        <span className="leaf-progress-circle-container__title" style={{ fontSize: `${setTitleFontSize()}px` }}>
          {titleValue}
        </span>
        <span className="leaf-progress-circle-container__subtitle">{subtitle}</span>
      </div>
      <div className="LeafProgressCircle">
        <ChartDonut
          themeColor={ChartThemeColor.purple}
          constrainToVisibleArea
          data={[
            { x: labels[0], y: currentAmount },
            { x: labels[1], y: targetAmount - currentAmount },
          ]}
          labels={({ datum }) => renderLabels(datum)}
          innerRadius={75}
          cornerRadius={3}
          padAngle={0}
          animate={{
            duration: animationDuration,
          }}
        />
      </div>
    </div>
  );
};

export default LeafProgressCircle;
