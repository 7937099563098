import { useTranslation } from "react-i18next";
import defaultContent from "../../../../content/bronzeRequirements";
import { useAppSelector } from "../../../../store";
import { storedLocale } from "../../../impersonation/util";
import { getLoginAffAbo } from "../../../../reducers/model/boot";
import { useGetBronzeRequirementsQuery } from "../../../../services/bronzeIncentivesAPI";
import "./BBIRequirements.scss";
import BronzeRequirementVCSPercent from "../../BronzeCommon/BronzeRequirements/BronzeRequirementVCSPercent/BronzeRequirementVCSPercent";
import BronzeRequirementPersonalPV from "../../BronzeCommon/BronzeRequirements/BronzeRequirementPersonalPV/BronzeRequirementPersonalPV";
import BronzeRequirementLegsPercent from "../../BronzeCommon/BronzeRequirements/BronzeRequirementLegsPercent/BronzeRequirementLegsPercent";
import BronzeRequirementPerfBonusLV from "../../BronzeCommon/BronzeRequirements/BronzeRequirementPerfBonusLV/BronzeRequirementPerfBonusLV";
import BronzeRequirementVCSPV from "../../BronzeCommon/BronzeRequirements/BronzeRequirementVCSPV/BronzeRequirementVCSPV";
import BronzeRequirementVCSLegs from "../../BronzeCommon/BronzeRequirements/BronzeRequirementVCSLegs/BronzeRequirementVCSLegs";
import BronzeRequirementPersonalOrderPV from "../../BronzeCommon/BronzeRequirements/BronzeRequirementPersonalOrderPV/BronzeRequirementPersonalOrderPV";
import BronzeRequirementPersonalActivityPV from "../../BronzeCommon/BronzeRequirements/BronzeRequirementPersonalActivityPV/BronzeRequirementPersonalActivityPV";

type Props = {
  awardCode: number;
  show?: boolean;
};

const BBIRequirements = ({ awardCode, show = true }: Props) => {
  const { t } = useTranslation(["bbiRequirements"]);

  const locale = storedLocale();

  const boot = useAppSelector(({ boot }) => boot);
  const { user, configuration } = useAppSelector((state) => state.boot);
  const { selectedPeriod } = useAppSelector((state) => state.period);

  const affAbo = getLoginAffAbo({ boot });
  const { isStandardRetailEnhancementCountry = false } = configuration?.corePlus || {};

  const { data, isFetching } = useGetBronzeRequirementsQuery({
    affAbo: affAbo,
    period: selectedPeriod,
    awardCode: awardCode,
    locale: locale,
    countryCode: user.isoCountryCode,
    isStandarRetailEnhancementCountry: isStandardRetailEnhancementCountry,
  });

  const {
    personalVolume = {},
    legsPercentage = {},
    performanceBonus = {},
    verifiedCustomerPV = {},
    vcsPercent = {},
    personalOrderPV = {},
    vcsBonusLegs = {},
    personalActivityPV = {},
  } = data || {};

  if (show === false) return <></>;

  // The order of the requirements here will also reflect on the order of  (BBIIncentiveProgress) component
  return (
    <div className="bbi-requirements">
      <h2 className="bbi-requirements__header">{t("header", defaultContent["header"])}</h2>
      <div className="bbi-requirements__content">
        <BronzeRequirementPersonalPV {...personalVolume} isFetching={isFetching} />
        <BronzeRequirementPersonalOrderPV {...personalOrderPV} isFetching={isFetching} />
        <BronzeRequirementVCSPercent {...vcsPercent} isFetching={isFetching} />
        <BronzeRequirementVCSPV {...verifiedCustomerPV} isFetching={isFetching} />
        <BronzeRequirementPerfBonusLV {...performanceBonus} isFetching={isFetching} />
        <BronzeRequirementLegsPercent {...legsPercentage} isFetching={isFetching} />
        <BronzeRequirementVCSLegs {...vcsBonusLegs} isFetching={isFetching} />
        <BronzeRequirementPersonalActivityPV {...personalActivityPV} isFetching={isFetching} />
      </div>
    </div>
  );
};

export default BBIRequirements;
