import { formatCurrency } from "@amwaycommon/acc-mixins/l10n";
import { performanceYear } from "@amwaycommon/acc-mixins/src/modules/amway-period";
import { Trans, useTranslation } from "react-i18next";
import { TTCI_STATUS } from "../../../common/enums/ttci-enums";
import { TtciData } from "../../../common/interfaces/ttciData";
import LeafTile from "../../../common/leaf/LeafTile/LeafTile";
import { LeafColorBlock } from "../../../common/leaf/LeafColorBlock/LeafColorBlock";
import { LeafMessageBox } from "../../../common/leaf/LeafMessageBox/LeafMessageBox";
import { getDefaultCurrencyCode } from "../../../common/util/config";
import defaultContent from "../../../content/ttci";
import "./BannerTile.scss";
import { LeafLearnMoreLink } from "../../../common/leaf/LeafLearnMoreLink/LeafLearnMoreLink";
import { getPerformanceYearShort } from "../../../common/util/period";

type Props = {
  ttciData: TtciData;
  isNewPerformanceYear: boolean;
  period: string;
  country: string;
  locale: string;
  learnMoreLink: string;
  isLoading: boolean;
};

export default function BannerTile({
  ttciData,
  isNewPerformanceYear,
  period,
  country,
  locale,
  learnMoreLink,
  isLoading,
}: Props) {
  const { t } = useTranslation(["ttci"]);
  const { status, bonus, bonusCurrency, noLongerQualify, displayAnaVolTrackingWarning } = ttciData;
  const PY = `PY${getPerformanceYearShort(performanceYear(period).toString())}`;
  const bonusAmount = status === TTCI_STATUS.NOT_TRACKING ? 0 : bonus;
  const currentBonus = formatCurrency(bonusAmount, bonusCurrency || getDefaultCurrencyCode(), locale, country, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  const getMainBannerMessage = (data: TtciData, isNewPerformanceYear: boolean) => {
    let contentString = "onTrackTitle";
    const { status, manuallyApproved, noLongerQualify } = data;

    switch (true) {
      case isNewPerformanceYear:
        contentString = "newPerformanceYearTitle";
        break;
      case status === TTCI_STATUS.DENIED:
        contentString = "deniedTitle";
        break;
      case status === TTCI_STATUS.HELD:
        contentString = "onHoldTitle";
        break;
      case manuallyApproved:
        contentString = "manuallyApprovedTitle";
        break;
      case status === TTCI_STATUS.TRACKING:
        contentString = "onTrackTitle";
        break;
      case status === TTCI_STATUS.ACHIEVED:
        contentString = "achievedTitle";
        break;
      case noLongerQualify:
        contentString = "noLongerQualifiedTitle";
        break;
      case status === TTCI_STATUS.NOT_QUALIFIED:
        contentString = "notQualifiedTitle";
        break;
    }
    return (
      <Trans>
        {t(`bannerTile.${contentString}`, defaultContent.bannerTile[contentString], {
          currentPy: PY,
        })}
        <span className="bold-banner-text"></span>
      </Trans>
    );
  };

  const getSubTitleText = (data: TtciData) => {
    let contentString = `earnTtciSubTitle`;
    if (
      data.status === TTCI_STATUS.TRACKING ||
      data.status === TTCI_STATUS.NOT_QUALIFIED ||
      data.status === TTCI_STATUS.NOT_TRACKING ||
      data.noLongerQualify
    ) {
      contentString = "earnTtciSubTitle";
    }
    if (data.status === TTCI_STATUS.HELD || data.status === TTCI_STATUS.DENIED || data.manuallyApproved) {
      contentString = "contactAmway";
    }
    if (isNewPerformanceYear) {
      contentString = "newPerformanceYearSubTitle";
    }
    return t(`bannerTile.${contentString}`, defaultContent.bannerTile[contentString]);
  };

  return (
    <LeafTile isLoading={isLoading}>
      <div id="ttci-banner-tile" className="ttci-banner-container">
        <section className="ttci-banner-container__section-one">
          <div
            className={`ttci-banner-container__title${
              isNewPerformanceYear ? " ttci-banner-container__newyear-banner" : ""
            }`}
          >
            {getMainBannerMessage(ttciData, isNewPerformanceYear)}
          </div>
          <br />
          <div>{`${getSubTitleText(ttciData)}`}</div>
          <br />
        </section>
        <section className="ttci-banner-container__section-two">
          <div className="banner-message-box" hidden={!displayAnaVolTrackingWarning}>
            <LeafMessageBox color={"orange"}>{t("vcsReqMessage", defaultContent["vcsReqMessage"])}</LeafMessageBox>
          </div>
          <div hidden={isNewPerformanceYear || noLongerQualify}>
            <LeafColorBlock>
              <div>
                {t("bannerTile.potentialEarnings", defaultContent.bannerTile["potentialEarnings"], {
                  currentPy: PY,
                })}
              </div>
              <div className="lower-content">{currentBonus}</div>
            </LeafColorBlock>
          </div>
          <div className="ttci-banner-container__btn" hidden={learnMoreLink === ""}>
            <LeafLearnMoreLink href={learnMoreLink} />
          </div>
        </section>
      </div>
    </LeafTile>
  );
}
