import { formatCurrency } from "@amwaycommon/acc-mixins/l10n";
import { storedLocale } from "../../../impersonation/util";

type BronzeAmountInput = {
  isFixedPayment: boolean;
  isoCountry: string;
  bonus: {
    amount: number;
    isoCurrency: string;
  };
  consistencyPayment: {
    met: boolean;
    bonusAmount: number;
  };
};

export const getBronzeBonusAmount = ({ bonus, isFixedPayment, consistencyPayment, isoCountry }: BronzeAmountInput) => {
  const includeFixedPayment = isFixedPayment === true && consistencyPayment.met === true && bonus.amount > 0;
  const bonusAmountWithFixPayment = bonus.amount + consistencyPayment.bonusAmount;
  const bonusAmount = includeFixedPayment === true ? bonusAmountWithFixPayment : bonus.amount;
  const locale = storedLocale();
  if (bonusAmount > 0)
    return formatCurrency(Number(bonusAmount), bonus.isoCurrency, locale, isoCountry, {
      style: "currency",
    });
  return "0";
};
