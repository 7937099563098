import { useState } from "react";
import { useTranslation } from "react-i18next";
import { performanceYear, periodFromDate } from "@amwaycommon/acc-mixins/src/modules/amway-period";
import { useAppSelector } from "../../../../../../../store";
import LeafSelector, { Option } from "../../../../../../../common/leaf/LeafSelector/LeafSelector";
import { storedLocale } from "../../../../../../impersonation/util";
import LeafTableTwoCols from "../../../../../../../common/leaf/LeafTableTwoCols/LeafTableTwoCols";
import { PerfHistoryData, PerfHistoryResponse } from "../../../../../../../services/performanceHistoryAPI";
import LeafDateTime from "../../../../../../../common/leaf/LeafDateTime/LeafDateTime";
import LeafLineChart from "../../../../../../../common/leaf/LeafLineChart/LeafLineChart";
import { LeafErrorMessage } from "../../../../../../../common/leaf/LeafErrorMessage/LeafErrorMessage";
import { IconNoData } from "../../../../../../../common/icons/IconNoData/IconNoData";
import defaultContent from "../../../../../../../content/aboProfilePerfHistory";
import { businessKpiClickTrack } from "../../../../../../../common/tealium/ABOProfile/Profile/ProfileTabViewsTrack/InsightsTabTrack";
import ABOProfilePerfHistoryKPIs from "./ABOProfilePerfHistoryKPIs/ABOProfilePerfHistoryKPIs";
import theme from "./ABOProfilePerfHistory.module.scss";
import "./ABOProfilePerfHistory.scss";

const ABOProfilePerfHistory = () => {
  const { t } = useTranslation(["aboProfilePerfHistory"]);

  const locale = storedLocale();

  const affAbo = useAppSelector((state) => state.profile.affAbo);
  const { isoCountryCode } = useAppSelector((state) => state.boot?.user);
  const { perfHistory = [] } = useAppSelector((state) => state.boot?.configuration?.profile);

  const KPIS_WITH_LABEL = perfHistory.map((kpi: { id: string; selected?: boolean }) => ({
    ...kpi,
    label: t(kpi.id, defaultContent[kpi.id]),
  })) as Option[];

  const getPerformanceYears = (date: Date, count: number) => {
    const startFrom = performanceYear(periodFromDate(date));
    return Array.from({ length: count }, (_, index) => {
      const py = startFrom - index;
      return {
        id: `${py}08`, // last month of the PY
        label: `${t("performanceYear", defaultContent["performanceYear"])} ${py}`,
        selected: index === 0,
      };
    });
  };

  const [kpiOptions, setKPIOptions] = useState(KPIS_WITH_LABEL);
  const [pyOptions, setPYOptions] = useState(getPerformanceYears(new Date(), 3));

  const onSelectedKPIs = (option: Option) => {
    const updateKPIs = kpiOptions.map((kpi: Option) => ({
      ...kpi,
      selected: kpi.id === option.id,
    }));
    setKPIOptions(updateKPIs);
    businessKpiClickTrack(option.id, selectedPeriod.id);
  };

  const onSelectedPY = (option: Option) => {
    const updatePY = pyOptions.map((py: Option) => ({
      ...py,
      selected: py.id === option.id,
    }));
    setPYOptions(updatePY);
    businessKpiClickTrack(selectedKPI.id, option.id);
  };

  const formatTableDate = (data: PerfHistoryData) => {
    return <LeafDateTime date={data.isoDate} options={{ month: "long" }} ignoreTimeZone={true} />;
  };

  const formatTableKPIData = (data: PerfHistoryData) => {
    return data.decimalAmount || data.integerAmount;
  };

  const formatChartDate = (date: number | string) => {
    const dateObject = new Date(date);
    const formatter = new Intl.DateTimeFormat(locale, {
      month: "narrow",
      year: void 0,
      timeZone: "UTC",
    });
    const formattedDate = formatter.format(dateObject);
    return formattedDate;
  };

  const formatChartData = (kpi: number | string) => `${kpi}`;

  const selectedKPI: Option = kpiOptions.find(({ selected }: Option) => selected === true) || kpiOptions[0];
  const selectedPeriod: Option = pyOptions.find(({ selected }: Option) => selected === true) || pyOptions[0];
  const selectedView = ABOProfilePerfHistoryKPIs.find((view) => view.id === selectedKPI.id);

  return (
    <div className="abo-profile-perf-history">
      <h2 className="abo-profile-perf-history__title">{t("title", defaultContent["title"])}</h2>
      <div className="abo-profile-perf-history__content">
        <div className="abo-profile-perf-history__options">
          <div className="abo-profile-perf-history__options__kpis">
            <LeafSelector
              options={kpiOptions}
              onSelected={onSelectedKPIs}
              ariaLabel={`${t("kpiSelector", defaultContent["kpiSelector"])}`}
            />
          </div>
          <div className="abo-profile-perf-history__options__py">
            <LeafSelector
              options={pyOptions}
              onSelected={onSelectedPY}
              ariaLabel={`${t("pySelector", defaultContent["pySelector"])}`}
            />
          </div>
        </div>

        <div className="abo-profile-perf-history__content__data">
          {selectedView && (
            <selectedView.PerfHistoryData
              affAbo={affAbo}
              locale={locale}
              isoCountryCode={isoCountryCode}
              period={selectedPeriod.id}
            >
              {({ data, isFetching, isError, overrideFormatTableKPIData, overrideFormatChartData }) => {
                const noData = isFetching === false && (data?.annualTotal === 0 || isError);

                return (
                  <>
                    <div className={`abo-profile-perf-history__content__error--${noData && "show"}`}>
                      <LeafErrorMessage
                        show={noData}
                        icon={<IconNoData height="12.50rem" width="15.66rem" />}
                        headline={`${t("noData", defaultContent["noData"])}`}
                        fontSize="big"
                        gap={true}
                        noContent={true}
                      />
                    </div>
                    <div className="abo-profile-perf-history__content__data__chart">
                      <LeafLineChart<PerfHistoryResponse, PerfHistoryData>
                        dataset={data ? [data] : []}
                        series={"data"}
                        x={"isoDate"}
                        y={"integerAmount"}
                        formatX={formatChartDate}
                        formatY={overrideFormatChartData || formatChartData}
                        staticTheme={theme.brunt_orange}
                        isFetching={isFetching}
                        showTooltips={false}
                        show={!noData}
                      />
                    </div>
                    <div className="abo-profile-perf-history__content__data__table">
                      <LeafTableTwoCols<PerfHistoryData>
                        title={`${t(selectedKPI.id, defaultContent[selectedKPI.id])}`}
                        list={data?.data}
                        col1={"isoDate"}
                        col2={"integerAmount"}
                        isFetching={isFetching}
                        formatCol1={formatTableDate}
                        formatCol2={overrideFormatTableKPIData || formatTableKPIData}
                        show={!noData}
                      />
                    </div>
                  </>
                );
              }}
            </selectedView.PerfHistoryData>
          )}
        </div>
      </div>
    </div>
  );
};

export default ABOProfilePerfHistory;
