import { performanceYear } from "@amwaycommon/acc-mixins/dates";
import KeyMetricsTile from "../../../../common/components/KeyMetricsTile/KeyMetricsTile";
import { useAppSelector } from "../../../../store";
import { AWARD_CODES } from "../../../../common/enums/award-codes";
import { isProgramActive } from "../../../../common/util/checkProgramActiveStatus";
import { KPI } from "../../../../common/enums/kpis";
import { FetchKeyMetricsData } from "./AnnualKeyMetricsDataLayer";

export const AnnualKeyMetricsTile = () => {
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { savedKpiPreferencesData } = useAppSelector((state) => state.savedKpiPreferencesData);
  const { shellConfig } = useAppSelector((state) => state.boot);
  const { annualDashboard } = useAppSelector((state) => state.boot.configuration);

  const isFaaEligible = isProgramActive(shellConfig.eligibleIncentives, AWARD_CODES.FAA);

  let availableKpis = annualDashboard.keyMetrics.availableKpis;
  if (!isFaaEligible) {
    availableKpis = availableKpis.filter((kpi) => kpi !== KPI.SUPERLEGS);
  }

  const keyMetricsAPIData = FetchKeyMetricsData({
    period: selectedPeriod,
    performanceYear: performanceYear(selectedPeriod).toString(),
    availableKpis: availableKpis,
    savedKpiPreferencesData,
  });

  return (
    <KeyMetricsTile
      keyMetricsAPIData={keyMetricsAPIData}
      tealiumDashboardName="annual"
      keyMetricsConfig={annualDashboard.keyMetrics}
    />
  );
};
