import { formatAboBV, formatAboPV, formatPercentage, formatInteger } from "@amwaycommon/acc-mixins/l10n";

/**
 * remove new line in the given stringValue
 * and replace multiple empty spaces to single empty space
 * end result will be single line of string
 * @param {String} stringValue
 */
export const toSingleLine = (stringValue: string) => {
  if (!stringValue) {
    return stringValue;
  }
  return stringValue
    .trim()
    .replace(/(?:\r\n|\r|\n)/g, "")
    .replace(/\s\s+/g, " ");
};

/**
 * Determines what formatting is used for KPI value. The default behavior is to format as an integer.
 *
 * @param {string} kpiKey
 * @param {string} kpiValue
 * @returns {string}
 */
export function formatKpiValue(kpiKey: string, kpiValue: number, market: string, locale: string): string {
  if (kpiKey.match(/percent/gi)) {
    return formatPercentage(Math.trunc(kpiValue) / 100, locale);
  } else if (kpiKey.match(/PV|pointsToNextLevel/gi)) {
    return formatAboPV(kpiValue, locale, market);
  } else if (kpiKey.match(/BV/gi)) {
    return formatAboBV(kpiValue, locale, market);
  } else {
    return formatInteger(kpiValue, locale);
  }
}
